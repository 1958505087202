<template>
  <div>
    <div class="outer-container">
      <div class="inner-container">
        <bleed-container bleed="left" class="relative">
          <div class="box bg-grey-100">
            <div class="bleed-wrapper relative z-20">
              <div class="px-8">
                <div class="max-w-screen-lg m-auto relative">
                  <div class="inner-box py-14 md:py-28">
                    <div class="row lg:flex lg:flex-row">
                      <div class="col lg:text-right lg:whitespace-nowrap">
                        <div class="text-18 md:text-34 font-bold">Your details —</div>
                        <div class="mt-4 text-grey-500">Update and manage your contact details</div>
                      </div>
                      <div class="col lg:flex-1 lg:ml-24">
                        <form action="#" @submit.prevent="submitForm" autocomplete="off">
                          <div class="row mt-8 md:mt-12 lg:mt-0 md:flex md:flex-row -mx-4">
                            <div class="col flex-1 px-4">
                              <div class="fields space-y-8">
                                <div class="field">
                                  <label for="firstName">First name</label>
                                  <input
                                      id="firstName"
                                      type="text"
                                      name="firstName"
                                      placeholder="First name*"
                                      v-model="form.firstName"
                                      @input="$v.form.firstName.$touch"
                                      class="input-text-black"
                                      :class="[{ 'error': $v.form.firstName.$invalid && $v.form.firstName.$dirty }, { 'success': $v.form.firstName.$dirty && !$v.form.firstName.$invalid }]"
                                  />
                                  <div class="block text-label text-red-600 mt-1" v-if="!$v.form.firstName.required && $v.form.firstName.$dirty">First name is required</div>
                                </div>
                                <div class="field">
                                  <label for="lastName">Last name</label>
                                  <input
                                      id="lastName"
                                      type="text"
                                      name="lastName"
                                      placeholder="Last name*"
                                      v-model="form.lastName"
                                      @input="$v.form.lastName.$touch"
                                      class="input-text-black"
                                      :class="[{ 'error': $v.form.lastName.$invalid && $v.form.lastName.$dirty }, { 'success': $v.form.lastName.$dirty && !$v.form.lastName.$invalid }]"
                                  />
                                  <div class="block text-label text-red-600 mt-1" v-if="!$v.form.lastName.required && $v.form.lastName.$dirty">Last name is required</div>
                                </div>
                                <div class="field">
                                  <label for="email">Email address</label>
                                  <input
                                      id="email"
                                      type="email"
                                      name="email"
                                      placeholder="Email address*"
                                      v-model="form.email"
                                      @input="$v.form.email.$touch"
                                      class="input-text-black"
                                      disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col flex-1 px-4">
                              <div class="fields space-y-8 mt-8 md:mt-0">
                                <div class="field">
                                  <label for="company">Company name</label>
                                  <input
                                      id="company"
                                      type="text"
                                      name="company"
                                      placeholder="Company name*"
                                      v-model="form.company"
                                      @input="$v.form.company.$touch"
                                      class="input-text-black"
                                      :class="[{ 'error': $v.form.company.$invalid && $v.form.company.$dirty }, { 'success': $v.form.company.$dirty && !$v.form.company.$invalid }]"
                                  />
                                  <div class="block text-label text-red-600 mt-1" v-if="!$v.form.company.required && $v.form.company.$dirty">Company name is required</div>
                                </div>
                                <div class="field">
                                  <label for="role">Job title</label>
                                  <input
                                      id="role"
                                      type="text"
                                      name="role"
                                      placeholder="Occupation / Role*"
                                      v-model="form.role"
                                      @input="$v.form.role.$touch"
                                      class="input-text-black"
                                      :class="[{ 'error': $v.form.role.$invalid && $v.form.role.$dirty }, { 'success': $v.form.role.$dirty && !$v.form.role.$invalid }]"
                                  />
                                  <div class="block text-label text-red-600 mt-1" v-if="!$v.form.role.required && $v.form.role.$dirty">Occupation / Role is required</div>
                                </div>
                                <div class="field">
                                  <label for="industry">Industry type</label>
                                  <select
                                      id="industry"
                                      type="text"
                                      name="industry"
                                      placeholder="Industry category*"
                                      v-model="form.industry"
                                      @change="$v.form.industry.$touch"
                                      class="input-text-black"
                                      :class="[{ 'error': $v.form.industry.$invalid && $v.form.industry.$dirty }, { 'success': $v.form.industry.$dirty && !$v.form.industry.$invalid }]
                      ">
                                    <option value="">Industry category*</option>
                                    <option :value="item" v-for="(item, index) in industryCategories" :key="'cat' + index">{{item}}</option>
                                  </select>
                                  <div class="block text-label text-red-600 mt-1" v-if="!$v.form.industry.required && $v.form.industry.$dirty">Industry category is required</div>
                                </div>
                                <div class="field">
                                  <label for="website">Company website</label>
                                  <input
                                      id="website"
                                      type="text"
                                      name="website"
                                      placeholder="Company website"
                                      v-model="form.website"
                                      class="input-text-black"
                                  />
                                </div>
                                <div class="field">
                                  <label for="country">Country</label>
                                  <select
                                      id="country"
                                      type="text"
                                      name="country"
                                      placeholder="Country*"
                                      v-model="form.country"
                                      @change="$v.form.country.$touch"
                                      class="input-text-black"
                                      :class="[{ 'error': $v.form.country.$invalid && $v.form.country.$dirty }, { 'success': $v.form.country.$dirty && !$v.form.country.$invalid }]
                      ">
                                    <option value="">Country*</option>
                                    <option value="Afganistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bonaire">Bonaire</option>
                                    <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Canary Islands">Canary Islands</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Channel Islands">Channel Islands</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos Island">Cocos Island</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote DIvoire">Cote DIvoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curaco">Curacao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="East Timor">East Timor</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands">Falkland Islands</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Ter">French Southern Ter</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Great Britain">Great Britain</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="India">India</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea North">Korea North</option>
                                    <option value="Korea Sout">Korea South</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macau">Macau</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Midway Islands">Midway Islands</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Nambia">Nambia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherland Antilles">Netherland Antilles</option>
                                    <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                    <option value="Nevis">Nevis</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau Island">Palau Island</option>
                                    <option value="Palestine">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Phillipines">Philippines</option>
                                    <option value="Pitcairn Island">Pitcairn Island</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Republic of Montenegro">Republic of Montenegro</option>
                                    <option value="Republic of Serbia">Republic of Serbia</option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="St Barthelemy">St Barthelemy</option>
                                    <option value="St Eustatius">St Eustatius</option>
                                    <option value="St Helena">St Helena</option>
                                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                    <option value="St Lucia">St Lucia</option>
                                    <option value="St Maarten">St Maarten</option>
                                    <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                    <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                    <option value="Saipan">Saipan</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="Samoa American">Samoa American</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syria</option>
                                    <option value="Tahiti">Tahiti</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Erimates">United Arab Emirates</option>
                                    <option value="United States of America">United States of America</option>
                                    <option value="Uraguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City State">Vatican City State</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                    <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                    <option value="Wake Island">Wake Island</option>
                                    <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zaire">Zaire</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                  </select>
                                  <div class="block text-label text-red-600 mt-1" v-if="!$v.form.country.required && $v.form.country.$dirty">Country is required</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-8 md:mt-12 md:flex md:flex-row -mx-4 justify-end">
                            <div class="col px-4 mt-8 md:mt-0 text-right">
                              <button type="submit" class="btn-white btn-wide">Update</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </bleed-container>


        <div class="tabs border-b border-white border-opacity-10 mt-16 md:mt-24">
          <div class="outer-container">
            <div class="inner-container">
              <div class="tab-buttons flex flex-row items-center -mx-8">
                <button type="button" class="h-28 md:h-40 focus:outline-none flex flex-row items-center px-8 md:mr-16" :class="{'active': view === 'wineries'}" @click="viewWineries">My Wineries ({{favouriteWineries.length}})</button>
                <button type="button" class="h-28 md:h-40 focus:outline-none flex flex-row items-center px-8" :class="{'active': view === 'products'}" @click="viewProducts">My Products ({{favouriteProducts.length}})</button>
                <button type="button" class="h-28 md:h-40 focus:outline-none flex flex-row items-center px-8" :class="{'active': view === 'ratings'}" @click="viewRatings">My Ratings ({{ratedProducts.length}})</button>
<!--                <button type="button" class="h-28 md:h-40 focus:outline-none flex flex-row items-center px-8" :class="{'active': view === 'itineraries'}" @click="viewItineraries">My Itineraries ({{itineraries.length}})</button>-->
              </div>
            </div>
          </div>
        </div>

        <favourite-wineries v-show="view === 'wineries'" class="mt-16" :wineries="favouriteWineries" />
        <favourite-products v-show="view === 'products'" class="mt-16" :products="favouriteProducts" />
        <favourite-products v-show="view === 'ratings'" class="mt-16" :products="ratedProducts" :show-rating="true" />
<!--        <favourite-itineraries v-show="view === 'itineraries'" class="mt-16" :itineraries="itineraries" />-->
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Analytics from '@/analytics'
import Vue from 'vue'
import Vuelidate from 'vuelidate/dist/vuelidate.min'
import { required  } from 'vuelidate/dist/validators.min'
Vue.use(Vuelidate)
// import auth0 from 'auth0-js';
import BleedContainer from "@/components/BleedContainer";
import FavouriteWineries from "@/components/FavouriteWineries";
import FavouriteProducts from "@/components/FavouriteProducts";
import Kontent from "@/kontent";
import Bus from '@/events/EventBus';
export default {
  name: 'Profile',
  components: {FavouriteProducts, FavouriteWineries, BleedContainer},
  props: {},
  data () {
    return {
      industryCategories: null,
      form: {
        firstName: this.$auth.user['https://australianwine.com/first_name'] ? this.$auth.user['https://australianwine.com/first_name'] : '',
        lastName: this.$auth.user['https://australianwine.com/last_name'] ? this.$auth.user['https://australianwine.com/last_name'] : '',
        email: this.$auth.user.email ? this.$auth.user.email : '',
        company: this.$auth.user['https://australianwine.com/company_name'] ? this.$auth.user['https://australianwine.com/company_name'] : '',
        role: this.$auth.user['https://australianwine.com/job_role'] ? this.$auth.user['https://australianwine.com/job_role'] : '',
        industry: this.$auth.user['https://australianwine.com/industry_category'] ? this.$auth.user['https://australianwine.com/industry_category'] : '',
        website: this.$auth.user['https://australianwine.com/company_website'] ? this.$auth.user['https://australianwine.com/company_website'] : '',
        country: this.$auth.user['https://australianwine.com/country'] ? this.$auth.user['https://australianwine.com/country'] : ''
      },
      view: 'wineries',
      favouriteProducts: [],
      favouriteWineries: [],
      ratedProducts: [],
      itineraries: []
    }
  },
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required
      },
      company: {
        required
      },
      role: {
        required
      },
      industry: {
        required
      },
      country: {
        required
      }
    }
  },
  methods: {
    validateForm () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    submitForm (e) {
      if (this.validateForm(e)) {

        api.updateProfile({
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          companyName: this.form.company,
          jobRole: this.form.role,
          industryCategory: this.form.industry,
          companyWebsite: this.form.website,
          country: this.form.country,
          acceptPersonal: this.form.acceptPersonal,
          acceptComms: this.form.acceptComms,
          acceptInformation: this.form.acceptInformation,
          acceptAge: this.form.acceptAge,
          authState: null
        }).then(() => {
          window.location.href = '/profile'
        })

        // this.$auth.getTokenSilently().then((token) => {
        //   const auth0Manage = new auth0.Management({
        //     domain: process.env.VUE_APP_AUTH_DOMAIN,
        //     token
        //   });
        //   auth0Manage.patchUserMetadata(this.$auth.user.sub, {
        //     first_name: this.form.firstName,
        //     last_name: this.form.lastName,
        //     company_name: this.form.company,
        //     job_role: this.form.role,
        //     industry_category: this.form.industry,
        //     company_website: this.form.website,
        //     country: this.form.country,
        //     accept_comms: true,
        //     accept_information: true,
        //     accept_age: true
        //   }, () => {
        //     window.location.href = '/profile'
        //   })
        // })
      }
    },
    viewWineries() {
      this.view = 'wineries'
    },
    viewProducts() {
      this.view = 'products'
    },
    viewRatings() {
      this.view = 'ratings'
    },
    viewItineraries() {
      this.view = 'itineraries'
    }
  },
  mounted () {
    Analytics.trackPage('Profile')
    this.$store.dispatch("getFavouriteProducts")
    this.$store.dispatch("getFavouriteWineries")
    this.$store.dispatch("getRatedProducts")
    // this.$store.dispatch("getItineraries")

    Kontent.getIndustryTypes().then((res) => {
      let cats = []
      for (let i = 0; i < res.data.terms.length; i++) {
        if (res.data.terms[i].name === 'Australian Wine Sector') {
          cats.push('Connect Exhibitor/Australian Winery')
        } else {
          cats.push(res.data.terms[i].name)
        }
      }
      this.industryCategories = cats
    })

    Bus.$on('favourite-products', (ids) => {
      Kontent.getFavouriteProducts(ids.join(',')).then((res) => {
        this.favouriteProducts = res.data.items
      })
    })
    Bus.$on('rated-products', (ids) => {
      Kontent.getFavouriteProducts(ids.join(',')).then((res) => {
        this.ratedProducts = res.data.items
      })
    })
    Bus.$on('favourite-wineries', (ids) => {
      Kontent.getFavouriteWineries(ids.join(',')).then((res) => {
        this.favouriteWineries = res.data.items
      })
    })
    Bus.$on('favourite-itineraries', (ids) => {
      this.itineraries = ids
    })
  }
}
</script>

<style scoped lang="scss">
label {
  font-weight: bold;
  font-size: 9px;
  text-transform: uppercase;
  color: #585858;
  margin-bottom: 15px;
  display: block;
}
.tabs {
  font-size: 16px;
  @media(min-width: 768px)
  {
    font-size: 22px;
  }
}
.tab-buttons {
  button {
    position: relative;
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 5px;
      bottom: 0;
      height: 1px;
      transition: all 0.3s;
      background-color: #E8490F;
      opacity: 0;
      width: 70%;
    }

    &:hover {
      &:after {
        height: 2px;
        opacity: 1;
      }
    }

    &.active, &.active:hover {
      &:after {
        height: 8px;
        opacity: 1;
      }
    }
  }
}
</style>
