<template>
  <div class="favourite-products">
    <masonry
        :cols="{default: 4, 1023: 3, 767:2, 400:1}"
        :gutter="{default: '30px'}"
    >
      <product-tile :show-rating="showRating" :product="product" :index="index" v-for="(product, index) in products" :key="'product' + index" class="w-full mt-12"></product-tile>
    </masonry>
  </div>
</template>

<script>
import ProductTile from "@/components/ProductTile";
import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry);
export default {
  name: 'FavouriteProducts',
  components: {ProductTile},
  props: {
    products: {
      type: Array,
      default () {
        return []
      }
    },
    showRating: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.favourite-products > div > div:nth-child(even) {
  margin-top: -30px;
}
</style>
