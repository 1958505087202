<template>
  <div class="favourite-wineries">
    <masonry
        :cols="{default: 3, 1023: 2, 767: 1}"
        :gutter="{default: '30px'}"
    >
      <winery-tile :winery="winery" :index="index" v-for="(winery, index) in wineries" :key="'winery' + index" class="w-full mt-12"></winery-tile>
    </masonry>
  </div>
</template>

<script>
import WineryTile from "@/components/WineryTile";
import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry);
export default {
  name: 'FavouriteWineries',
  components: {WineryTile},
  props: {
    wineries: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style lang="scss">
.favourite-wineries > div > div:nth-child(even) {
  margin-top: -30px;
}
</style>
